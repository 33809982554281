import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';

const baseURL = import.meta.env.VITE_OIDC_AUTHORITY;

interface Request {
  personal_code: string;
}

const verifyCode = async (data: Request, token: string) => {
  await axios.request({
    method: 'POST',
    baseURL,
    url: '/user/personal-code/confirm',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export function useVerifyCode() {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useMutation({
    mutationFn: (data: Request) => verifyCode(data, token!),
  });
}
