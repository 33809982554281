/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  MRT_ColumnDef,
  SideTabRef,
  Table,
  TableRef,
  useTranslations,
} from '@omnipkg/pa-ui-kit';
import { Dots } from 'src/assets/icons';

import AddUserMenu from './components/AddUserMenu/AddUserMenu';
import UserCard from './components/UserCard';
import { useFilters } from './hooks/useFilters';
import { useUsersData } from './hooks/useUsersData';
import { User } from './types';
import styles from './Users.module.scss';

export default function Users() {
  const [tableRef, setTableRef] = useState<TableRef | null>(null);

  const addUserRef = useRef<SideTabRef>(null);

  const navigate = useNavigate();

  const { companyFilter, profileFilter, statusFilter } = useFilters(tableRef);

  const { data, isFetching, isLoading, isRefetching, refetch } = useUsersData({
    searchValue: tableRef?.searchValue,
    sortingState: tableRef?.sortingState,
    paginationState: tableRef?.paginationState,
    selectedFilters: tableRef?.selectedFilters,
  });

  const users = data?.data;
  const pagination = data?.extra;
  const { items_count: itemsCount = 0, num_pages: numPages = 0 } =
    pagination ?? {};

  const { t } = useTranslations();

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('users.name'),
        accessorFn: (row) => {
          const fullName = `${row.first_name || ''} ${row.last_name || ''}`;
          return fullName.trim();
        },
      },
      {
        accessorKey: 'email',
        header: t('users.email'),
      },
      {
        accessorKey: 'phone',
        header: t('users.phone'),
      },
      {
        accessorKey: 'status',
        header: t('users.status'),
        accessorFn: (row) => {
          const statusValue = row.status;
          const status = statusValue ? t(`users.status.${statusValue}`) : '-';

          return (
            <span className={cn(styles.status, styles[`status${statusValue}`])}>
              {status}
            </span>
          );
        },
      },
      {
        accessorKey: 'company',
        header: t('users.company'),
        accessorFn: (row) => row.company?.name,
      },
      {
        accessorKey: 'profile.name',
        header: t('users.profile'),
        accessorFn: (row) => {
          const profileValue = row.profile?.prototype;
          const isProfileExist = Number.isInteger(profileValue);
          const profile = isProfileExist
            ? t(`users.profile.${profileValue}`)
            : '-';
          return (
            <span className={isProfileExist ? styles.profile : undefined}>
              {profile}
            </span>
          );
        },
      },
      {
        accessorKey: 'viewMore',
        header: t('users.view_more'),
        enableSorting: false,
        Cell: ({ row }) => {
          const { uid } = row.original;
          return (
            <Dots
              className={styles.dots}
              onClick={() => {
                navigate({ search: `user_uid=${uid}` });
              }}
            />
          );
        },
      },
    ],
    [t, navigate],
  );

  return (
    <>
      <Table
        ref={setTableRef}
        table={{
          data: users ?? [],
          columns,
          showSkeletons: isLoading,
          showLoadingOverlay: isFetching || isRefetching,
          rowCount: itemsCount,
          pageCount: numPages,
          emptyRowsFallbackMessage: t('users.empty'),
        }}
        filters={[companyFilter, profileFilter, statusFilter]}
        extraComponent={
          <Button
            variant="primary"
            label={t('users.add_user')}
            onClick={() => addUserRef.current?.open()}
          />
        }
      />
      <UserCard refetchUsers={refetch} />
      <AddUserMenu addUserRef={addUserRef} />
    </>
  );
}
