import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { User } from 'src/pages/Users/types';

export interface CommonResponse<T> {
  success: boolean;
  status_code: number;
  message: string | null;
  ids: string | null;
  data: T;
  extra: {
    items_count: number;
    next: number;
    num_pages: number;
    page: number;
    per_page: number;
  };
}

interface UserRequest {
  token: string;
  uid: string;
}

async function getUserData<T>({ token, uid }: UserRequest) {
  const res = await axios.request<CommonResponse<T>>({
    baseURL: import.meta.env.VITE_OIDC_AUTHORITY,
    url: `contract/users?uid=${uid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return res.data;
}

export function useUserData(uid?: string) {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useQuery({
    queryKey: ['user', uid],
    queryFn: () =>
      getUserData<User[]>({
        token: token!,
        uid: uid!,
      }),
    enabled: !!token && !!uid,
  });
}
