/* eslint-disable camelcase */
import {
  MRT_PaginationState,
  MRT_SortingState,
  SelectedFilter,
} from '@omnipkg/pa-ui-kit';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { CommonResponse } from 'src/api/types';
import { Company } from 'src/api/useCompaniesData';
import { Profile } from 'src/api/useProfilesData';

import { FilterIndex, Status, User } from '../types';

interface UseUsersData {
  sortingState?: MRT_SortingState;
  paginationState?: MRT_PaginationState;
  searchValue?: string;
  selectedFilters?: SelectedFilter[];
}

interface UsersRequest
  extends Required<
    Pick<UseUsersData, 'sortingState' | 'paginationState' | 'searchValue'>
  > {
  token: string;
  company?: Company;
  profile?: Profile;
  status?: Status;
}

async function getUsersData<T>({
  token,
  searchValue,
  sortingState,
  paginationState,
  company,
  profile,
  status,
}: UsersRequest) {
  const { pageSize } = paginationState;
  const page = paginationState.pageIndex + 1;
  const sortBy = sortingState[0]?.id;
  const sortDirection = sortingState[0]?.desc ? 'desc' : 'asc';

  const sortingParams =
    sortBy && sortDirection
      ? `&sort_by=${sortBy}&sort_direction=${sortDirection}`
      : '';
  const searchParams = searchValue ? `&q=${searchValue}` : '';
  const companyParams = company ? `&company_uid=${company.uid}` : '';
  const profileParams = profile ? `&profile_uid=${profile.uid}` : '';
  const statusParams = status ? `&status=${status.status}` : '';

  const res = await axios.request<CommonResponse<T>>({
    baseURL: import.meta.env.VITE_OIDC_AUTHORITY,
    url: `contract/users?page=${page}&page_size=${pageSize}${sortingParams}${searchParams}${companyParams}${profileParams}${statusParams}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return res.data;
}

export function useUsersData({
  searchValue,
  paginationState,
  sortingState,
  selectedFilters,
}: UseUsersData) {
  const { userData } = useAuth();
  const token = userData?.access_token;

  const company = selectedFilters?.find(
    (filter) => filter.filterIndex === FilterIndex.COMPANY,
  )?.data;
  const profile = selectedFilters?.find(
    (filter) => filter.filterIndex === FilterIndex.PROFILE,
  )?.data;
  const status = selectedFilters?.find(
    (filter) => filter.filterIndex === FilterIndex.STATUS,
  )?.data;

  const validSearchValue =
    searchValue && searchValue?.length > 2 ? searchValue : '';

  return useQuery({
    queryKey: [
      'users',
      validSearchValue,
      paginationState,
      sortingState,
      company,
      profile,
      status,
    ],
    queryFn: () =>
      getUsersData<User[]>({
        token: token!,
        paginationState: paginationState!,
        sortingState: sortingState!,
        company: company as Company | undefined,
        profile: profile as Profile | undefined,
        status: status as Status | undefined,
        searchValue: validSearchValue,
      }),
    enabled: !!token && !!sortingState && !!paginationState,
  });
}
