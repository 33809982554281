import { type FC, useEffect, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { Lang, useTranslations } from '@omnipkg/pa-ui-kit';
import { ChevronDownIcon } from 'src/assets/icons';
import { useUserContext } from 'src/context/userContext';

import styles from './LocaleSelector.module.scss';
import type { LocaleSelectorProps } from './LocaleSelector.types';

export const LocaleSelector: FC<LocaleSelectorProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useUserContext();
  const { availableLangs, langData, setProjectLangs, selectLang } =
    useTranslations();

  useEffect(() => {
    if (user?.organization?.pa_languages) {
      setProjectLangs(user.organization.pa_languages as Lang[]);
    }
  }, [user, setProjectLangs]);

  const { context, floatingStyles, refs } = useFloating({
    open: isOpen,
    placement: 'bottom-start',
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const handleLangSelection = (lang: Lang) => () => {
    selectLang(lang);
    setIsOpen(false);
  };

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        className={cn(styles.root, className)}
        {...getReferenceProps()}
      >
        <span className={styles.title}>{langData.lang.toUpperCase()}</span>
        <ChevronDownIcon className={styles.icon} />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={styles.list}
            {...getFloatingProps()}
          >
            {availableLangs.map(({ lang }) => (
              <div
                key={lang}
                role="presentation"
                className={styles.listItem}
                onClick={handleLangSelection(lang)}
              >
                {lang.toUpperCase()}
              </div>
            ))}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
