/* eslint-disable jsx-a11y/role-supports-aria-props */

import { type FC, useRef } from 'react';
import { hasScopeAccess, useOutsideClick } from '@omnic/lk-core';
import { useTranslations } from '@omnipkg/pa-ui-kit';
import { useAuth } from 'oidc-react';
import { NavigationItem } from 'src/components/NavigationItem';
import { useSidebarMenuItems } from 'src/constants';

import { useSidebar } from '../../context/sidebar';
import styles from './Sidebar.module.scss';
import type { SidebarProps } from './Sidebar.types';

export const Sidebar: FC<SidebarProps> = ({ className }) => {
  const { t } = useTranslations();
  const authContext = useAuth();
  const slideOutRef = useRef<HTMLDivElement>(null);

  const { selected, setSelected } = useSidebar();

  useOutsideClick({
    isOpen: !!selected,
    onOutsideClick: () => setSelected(null),
    ref: slideOutRef,
  });

  const sidebarMenuItems = useSidebarMenuItems();

  const availableItems = sidebarMenuItems.filter(
    (menuItem) =>
      !menuItem.access || hasScopeAccess(menuItem.access)(authContext),
  );

  return (
    <aside aria-expanded={!!selected} className={cn(styles.root, className)}>
      <ul className={styles.groupList}>
        {availableItems?.map((menuItem) => {
          return <NavigationItem key={menuItem.id} menuItem={menuItem} />;
        })}
      </ul>
      {!!selected && (
        <div className={styles.slideOut} ref={slideOutRef}>
          <h1 className={styles.slideOutHead}>{t('select_a_report')}</h1>
          <ul className={styles.slideOutList}>
            {selected.children
              ?.filter(
                (menuItem) =>
                  !menuItem.access ||
                  hasScopeAccess(menuItem.access)(authContext),
              )
              ?.map((menuItem) => (
                <NavigationItem key={menuItem.id} isChild menuItem={menuItem} />
              ))}
          </ul>
        </div>
      )}
    </aside>
  );
};
