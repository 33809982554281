import { getCurrentUserV2 } from '@omnic/lk-core';
import { useQuery } from '@tanstack/react-query';

export const useCurrentUser = () => {
  return useQuery({
    queryKey: ['omnic_id_user'],
    queryFn: () =>
      getCurrentUserV2({ baseURL: import.meta.env.VITE_OIDC_AUTHORITY }),
  });
};
