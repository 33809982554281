import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { CommonResponse } from 'src/api/types';

export interface Company {
  country_code: string;
  created_at: string;
  logo?: string;
  name: string;
  ownership_from: number;
  reg_number: string;
  subject_type: number;
  uid: string;
  updated_at?: string;
  website?: string;
  contact: {
    email: string;
    name: string;
    phone: string;
  };
  contract: {
    number: string;
    uid: string;
  };
}

interface Args {
  token: string;
}

async function getCompanies<T>({ token }: Args) {
  const res = await axios.request<CommonResponse<T>>({
    baseURL: import.meta.env.VITE_OIDC_AUTHORITY,
    url: '/companies',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return res.data;
}

export function useCompaniesData() {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useQuery({
    queryKey: ['companies'],
    queryFn: () =>
      getCompanies<Company[]>({
        token: token!,
      }),
    enabled: !!token && false,
  });
}
