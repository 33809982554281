import {
  createFilter,
  DataForSelect,
  TableRef,
  useTranslations,
} from '@omnipkg/pa-ui-kit';
import { Company, useCompaniesData } from 'src/api/useCompaniesData';
import { Profile, useProfilesData } from 'src/api/useProfilesData';

import { FilterIndex, Status, UserStatus } from '../types';

const userStatuses: Status[] = [
  {
    status: UserStatus.VERIFICATION_IS_NEEDED,
  },
  {
    status: UserStatus.VERIFICATION_REQUEST_SENT,
  },
  {
    status: UserStatus.ACTIVE,
  },
  {
    status: UserStatus.DEACTIVATED,
  },
];

export function useFilters(tableRef: TableRef | null) {
  const { t } = useTranslations();

  const filtersToApply = tableRef?.filtersToApply;
  const setFilters = tableRef?.setFilters;
  const removeFromFiltersToApply = tableRef?.removeFromFiltersToApply;

  const company = filtersToApply?.find(
    (filter) => filter.filterIndex === FilterIndex.COMPANY,
  );
  const profile = filtersToApply?.find(
    (filter) => filter.filterIndex === FilterIndex.PROFILE,
  );
  const status = filtersToApply?.find(
    (filter) => filter.filterIndex === FilterIndex.STATUS,
  );

  const {
    data: cData,
    isLoading: cIsFetching,
    refetch: cRefetch,
  } = useCompaniesData();

  const {
    data: pData,
    isLoading: pIsFetching,
    refetch: pRefetch,
  } = useProfilesData();

  const companies = cData?.data;
  const profiles = pData?.data;

  const companiesData = companies?.reduce<DataForSelect<Company>[]>(
    (acc, item) => {
      const value = item.name;
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  const profilesData = profiles?.reduce<DataForSelect<Profile>[]>(
    (acc, item) => {
      const value = item.name;
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  const statusesData = userStatuses.reduce<DataForSelect<Status>[]>(
    (acc, item) => {
      const value = t(`users.status.${item.status}`);
      acc.push({ value, data: item });
      return acc;
    },
    [],
  );

  const companyFilter = createFilter.selector<Company>({
    filterName: t('filters.companies'),
    filterData: {
      isLoading: cIsFetching,
      value: company?.value,
      dataForSelect: companiesData,
      onDataSelect: (data) => {
        setFilters?.(FilterIndex.COMPANY, data);
      },
      onOpenList: () => cRefetch(),
      onClear: () => removeFromFiltersToApply?.(FilterIndex.COMPANY),
    },
  });

  const profileFilter = createFilter.selector<Profile>({
    filterName: t('filters.profiles'),
    filterData: {
      isLoading: pIsFetching,
      value: profile?.value,
      dataForSelect: profilesData,
      onDataSelect: (data) => {
        setFilters?.(FilterIndex.PROFILE, data);
      },
      onOpenList: () => pRefetch(),
      onClear: () => removeFromFiltersToApply?.(FilterIndex.PROFILE),
    },
  });

  const statusFilter = createFilter.selector<Status>({
    filterName: t('filters.status'),
    filterData: {
      value: status?.value,
      dataForSelect: statusesData,
      onDataSelect: (data) => {
        setFilters?.(FilterIndex.STATUS, data);
      },
      onClear: () => removeFromFiltersToApply?.(FilterIndex.STATUS),
    },
  });

  return {
    companyFilter,
    profileFilter,
    statusFilter,
  };
}
