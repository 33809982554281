import { type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useScopeAccess } from '@omnic/lk-core';
import { useTranslations } from '@omnipkg/pa-ui-kit';
import { useAuth } from 'oidc-react';
import { SignOut, UserIcon, UserList, UsersThree } from 'src/assets/icons';
import { useUserContext } from 'src/context/userContext';
import { Routes } from 'src/types/routes';

import styles from './ProfileButton.module.scss';
import type { ProfileButtonProps } from './ProfileButton.types';

export const ProfileButton: FC<ProfileButtonProps> = ({
  className,
  openProfileMenu,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useUserContext();
  const hasAccess = useScopeAccess([
    'id:pc:admin',
    'pa:cc:dev:no',
    'pa:cc:dev:lu',
  ]);

  const { t } = useTranslations();
  const { signOut } = useAuth();

  const navigate = useNavigate();

  const { context, floatingStyles, refs } = useFloating({
    open: isOpen,
    placement: 'bottom-start',
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  function deleteAllCookies() {
    const cookies = document.cookie.split('; ');

    for (let c = 0; c < cookies.length; c++) {
      const d = window.location.hostname.split('.');

      while (d.length > 0) {
        const cookieBase = `${encodeURIComponent(
          cookies[c].split(';')[0].split('=')[0],
        )}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${d.join('.')} ;path=`;

        // eslint-disable-next-line no-restricted-globals
        const p = location.pathname.split('/');
        document.cookie = `${cookieBase}/`;

        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }

        d.shift();
      }
    }

    window.localStorage.clear();
  }

  const handleLogOut = async () => {
    setIsOpen(false);

    setTimeout(() => {
      deleteAllCookies();
    }, 0);

    setTimeout(() => {
      signOut();
    }, 0);
  };

  function onUsersClick() {
    navigate(Routes.USERS);
    setIsOpen(false);
  }

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        className={cn(styles.root, className)}
        {...getReferenceProps()}
      >
        <span className={styles.title}>{user?.login}</span>
        <UserIcon />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className={styles.list}
            {...getFloatingProps()}
          >
            {/* NOTE: hidden in https://omnicampus.atlassian.net/browse/OCHN-2115 */}
            {/* <div role="presentation" className={styles.listItem} onClick={handleListItemClick}> */}
            {/*   {t("change_password")} */}
            {/* </div> */}
            {/* <div className={styles.listSeparator} /> */}
            <button
              type="button"
              className={styles.listItem}
              onClick={openProfileMenu}
            >
              <UserList />
              {t('your_profile')}
            </button>
            {hasAccess && (
              <button
                type="button"
                className={styles.listItem}
                onClick={onUsersClick}
              >
                <UsersThree />
                {t('users')}
              </button>
            )}
            {/* <button type="button" className={styles.listItem}>
              <Buildings />
              {t("your_company")}
            </button>
            <button type="button" className={styles.listItem}>
              <Briefcase />
              {t("partner_companies")}
            </button> */}
            <div className={styles.listSeparator} />
            <button
              type="button"
              className={styles.listItem}
              onClick={handleLogOut}
            >
              <SignOut />
              {t('log_out')}
            </button>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
