import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { CommonResponse } from 'src/api/types';

export interface Profile {
  name: string;
  prototype: number;
  uid: string;
}

interface Args {
  token: string;
}

async function getProfiles<T>({ token }: Args) {
  const res = await axios.request<CommonResponse<T>>({
    baseURL: import.meta.env.VITE_OIDC_AUTHORITY,
    url: '/profiles/visible ',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return res.data;
}

export function useProfilesData() {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useQuery({
    queryKey: ['profiles'],
    queryFn: () =>
      getProfiles<Profile[]>({
        token: token!,
      }),
    enabled: !!token && false,
  });
}
