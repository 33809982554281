export interface UserInfoProps {
  onUpdate: () => void;
}

export enum UserDataValues {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  EMAIL = 'email',
}

export type UserData = {
  [K in UserDataValues]: string;
};
