import { useState } from 'react';
import { Button, useTranslations } from '@omnipkg/pa-ui-kit';
import { Arrow } from 'src/assets/icons';
import { UserStatus } from 'src/pages/Users/types';

import styles from './ChangeStatus.module.scss';
import { Props } from './types';
import { useChangeStatus } from './utils/changeStatus';

export default function ChangeStatus({
  user,
  toDefaultFlow,
  refetchUsers,
  refetchCurrentUser,
}: Props) {
  const {
    status,
    email,
    uid,
    contract: { uid: contractUid },
  } = user;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslations();
  const { mutate } = useChangeStatus();

  async function changeUserStatus(nextStatus: UserStatus) {
    setIsLoading(true);
    mutate(
      { user_uid: uid, contract_uid: contractUid, status: nextStatus },
      {
        onSuccess: async () => {
          await refetchUsers();
          await refetchCurrentUser();
          setIsLoading(false);
          toDefaultFlow();
        },
        onError: () => {
          setIsLoading(false);
        },
      },
    );
  }

  function getPageData() {
    if (status === UserStatus.VERIFICATION_IS_NEEDED) {
      return {
        nextStatusText: t('verification_code_has_been_sent'),
        message: t('verification_code_has_been_sent_message'),
        onConfirm: () => {
          changeUserStatus(UserStatus.VERIFICATION_REQUEST_SENT);
        },
      };
    }

    if (
      status === UserStatus.VERIFICATION_REQUEST_SENT ||
      status === UserStatus.ACTIVE
    ) {
      return {
        nextStatusText: t('deactivate_user'),
        message: t('deactivate_user_message'),
        onConfirm: () => {
          changeUserStatus(UserStatus.DEACTIVATED);
        },
      };
    }

    return {
      nextStatusText: t('activate_user'),
      message: t('activate_user_message'),
      onConfirm: () => {},
    };
  }

  const { nextStatusText, message, onConfirm } = getPageData();
  return (
    <div className={styles.main}>
      <h3 className={styles.userInfo}>{email}</h3>
      <div className={styles.action}>
        <Arrow />
        <span>{nextStatusText}</span>
      </div>
      <h2 className={styles.message}>{message}</h2>
      <div className={styles.buttons}>
        <Button
          label={t('no')}
          variant="outlined-light"
          onClick={toDefaultFlow}
        />
        <Button
          label={t('yes')}
          variant="primary"
          onClick={onConfirm}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
