import { RefetchUsers } from '../../types';

export type UserCardFlow = 'default' | 'change-status';

export interface Props {
  refetchUsers: RefetchUsers;
}

export enum InputName {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
}

export type PersonalData = {
  [K in InputName]: string;
};
