import { type FC, useState } from 'react';
import { SideTab } from '@omnipkg/pa-ui-kit';

import PasswordChange from './components/PasswordChange';
import UserInfo from './components/UserInfo';
import styles from './ProfileMenu.module.scss';
import type { ProfileMenuProps, View } from './types';

export const ProfileMenu: FC<ProfileMenuProps> = ({ sideTabRef }) => {
  const [view, setView] = useState<View>('user-info');

  function selectUserInfo() {
    setTimeout(() => {
      setView('user-info');
    }, 0);
  }

  function selectPasswordChange() {
    setTimeout(() => {
      setView('password-change');
    }, 0);
  }

  function getView() {
    switch (view) {
      case 'password-change':
        return <PasswordChange />;
      default:
        return <UserInfo onUpdate={selectPasswordChange} />;
    }
  }

  const isPasswordChange = view === 'password-change';

  return (
    <SideTab
      onClose={selectUserInfo}
      ref={sideTabRef}
      extraIcon={
        isPasswordChange ? { name: 'chevron-down', rotate: 90 } : undefined
      }
      onExtraIconClick={isPasswordChange ? selectUserInfo : undefined}
    >
      <div className={styles.main}>{getView()}</div>
    </SideTab>
  );
};
