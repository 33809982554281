import { type FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { paths } from '@omnic/lk-core';
import {
  AxiosProvider,
  ProtectedRoute,
  ScopeAccessRoute,
  SpinnerOverlay,
} from '@omnic/lk-ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { TFnType } from '@tolgee/web';
import { BillingApp } from 'billing/lib';
import { DeliveryApp } from 'delivery/lib';
import { DevicesApp } from 'devices/lib';

import { AuthProvider } from './layouts/AuthProvider';
import { PageLayout } from './layouts/PageLayout';
import Users from './pages/Users';
import { Routes } from './types/routes';

import './styles/index.scss';
import '@omnic/lk-ui/dist/style.css';

ReactGA.initialize('G-WS4VVNWVRD');

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: paths.root,
    element: <AuthProvider />,
    children: [
      {
        element: <AxiosProvider baseURL={import.meta.env.VITE_HUB_API_URL} />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                element: <PageLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={paths.devices.root} />,
                  },
                  {
                    path: Routes.USERS,
                    element: <Users />,
                    handle: { crumb: (t: TFnType) => t('users') },
                  },

                  // --- devices microservice ---
                  {
                    element: <ScopeAccessRoute access="cc:pc:allowed" />,
                    children: [
                      {
                        path: paths.devices.root,
                        handle: { crumb: (t: TFnType) => t('devices.title') },
                        children: DevicesApp.router,
                      },
                    ],
                  },

                  // --- billing microservice ---
                  {
                    element: <ScopeAccessRoute access="de:billing:user" />,
                    children: [
                      {
                        path: paths.billing.root,
                        handle: { crumb: (t: TFnType) => t('billing.title') },
                        children: BillingApp.router,
                      },
                    ],
                  },

                  // --- delivery microservice ---
                  {
                    element: <ScopeAccessRoute access="ec:pc:allowed" />,
                    children: [
                      {
                        path: paths.delivery.root,
                        handle: { crumb: (t: TFnType) => t('delivery.title') },
                        children: DeliveryApp.router,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: paths.login,
        element: <Navigate to={paths.root} />,
      },
    ],
  },
]);

export const App: FC = () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider fallbackElement={<SpinnerOverlay />} router={router} />
    </QueryClientProvider>
  );
};
