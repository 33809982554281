import { useRef, useState } from 'react';
import {
  Button,
  Input,
  InputRef,
  SideTab,
  useTranslations,
} from '@omnipkg/pa-ui-kit';
import { useUserContext } from 'src/context/userContext';

import styles from './CodeVerification.module.scss';
import { Props } from './types';
import { useVerifyCode } from './utils/verifyCode';

export function CodeVerification({ sideTabRef }: Props) {
  const [code, setCode] = useState('');
  const { t } = useTranslations();

  const inputRef = useRef<InputRef>(null);

  const { mutate, isPending } = useVerifyCode();
  const { refetch, isUserFetching } = useUserContext();

  function onConfirm() {
    const isCodeEmpty = inputRef.current?.isEmpty();

    if (!isCodeEmpty) {
      mutate(
        {
          personal_code: code,
        },
        {
          onSuccess: async () => {
            await refetch();
            sideTabRef.current?.close();
          },
          onError: () => {
            inputRef.current?.setInputError(
              t('verification_code_is_incorrect'),
            );
          },
        },
      );
    }
  }

  return (
    <SideTab ref={sideTabRef}>
      <div className={styles.main}>
        <div className={styles.textWrapper}>
          <h2 className={styles.title}>{t('verify_your_address.title')}</h2>
          <p className={styles.message}>{t('verify_your_address.message')}</p>
        </div>
        <Input
          required
          ref={inputRef}
          variant="editing"
          type="number"
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
        <Button
          isLoading={isPending || isUserFetching}
          label={t('confirm')}
          variant="primary"
          onClick={onConfirm}
        />
      </div>
    </SideTab>
  );
}
