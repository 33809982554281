import { InnovatedByOmnic } from 'src/assets/icons';

import styles from './Footer.module.scss';

export default function Footer(): JSX.Element {
  const currentYear = new Date().getFullYear();
  const copyRight = `© Omnic, All Rights Reserved ${currentYear}`;

  return (
    <div className={styles.footer}>
      <span className={styles.copyRight}>{copyRight}</span>
      <InnovatedByOmnic />
    </div>
  );
}
