import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useScopeAccess } from '@omnic/lk-core';
import { Button, Input, InputRef, useTranslations } from '@omnipkg/pa-ui-kit';
import { useUpdateUser } from 'src/api/updateUser';
import { useUserContext } from 'src/context/userContext';
import { UserStatus } from 'src/pages/Users/types';

import styles from '../../ProfileMenu.module.scss';
import { UserData, UserDataValues, UserInfoProps } from './types';

export default function UserInfo({ onUpdate }: UserInfoProps) {
  const [userData, setUserData] = useState<UserData>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const emailRef = useRef<InputRef>(null);

  const { t } = useTranslations();
  const isClient = useScopeAccess('cc:pc:person');

  const { user, setUser } = useUserContext();
  // const birth = (user as unknown as { birth: string })?.birth || '';
  const profileValue = user?.profile?.prototype;
  const profile = profileValue ? t(`users.profile.${profileValue}`) : '-';

  const isDifferentData =
    user?.first_name !== userData.firstName ||
    user?.last_name !== userData.lastName ||
    user?.phone !== userData.phone ||
    user?.email !== userData.email;

  const isAllDataExist =
    userData.firstName && userData.lastName && userData.phone && userData.email;

  const isActiveButton = isDifferentData && isAllDataExist;

  const { isPending, mutate } = useUpdateUser();

  useEffect(() => {
    if (user) {
      setUserData({
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.phone,
        email: user.email,
      });
    }
  }, [user]);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  }

  function saveChanges() {
    const isValidEmail = emailRef.current?.isEmailValid();
    if (isValidEmail) {
      mutate(
        {
          first_name: userData.firstName,
          last_name: userData.lastName,
          phone: userData.phone,
          email: userData.email,
        },
        {
          onSuccess: ({ data }) => {
            setUser(data);
          },
        },
      );
    }
  }

  const isActiveUser = user?.status === UserStatus.ACTIVE;
  const PersonalCode = user?.personal_code || '-';
  const city = user?.addresses?.[0]?.city;
  const street = user?.addresses?.[0]?.street;
  const house = user?.addresses?.[0]?.house;
  const zipCode = user?.addresses?.[0]?.zip_code;
  const fullAddress =
    city && street && house && zipCode
      ? `${city}, ${street}, ${house}, ${zipCode}`
      : '-';

  return (
    <>
      <h2 className={styles.title}>{t('your_profile')}</h2>
      <form className={styles.form}>
        <Input
          variant="editing"
          label={t('first_name')}
          value={userData.firstName}
          name={UserDataValues.FIRST_NAME}
          onChange={onChange}
        />
        <Input
          variant="editing"
          label={t('last_name')}
          value={userData.lastName}
          name={UserDataValues.LAST_NAME}
          onChange={onChange}
        />
        <Input
          variant="editing"
          label={t('phone')}
          value={userData.phone}
          name={UserDataValues.PHONE}
          onChange={onChange}
        />
        <Input
          ref={emailRef}
          variant="editing"
          label={t('email')}
          value={userData.email}
          name={UserDataValues.EMAIL}
          onChange={onChange}
        />
        <Input disabled label={t('profile')} value={profile} />
        {isClient && (
          <Input label={t('users.address')} disabled value={fullAddress} />
        )}
        {isClient && isActiveUser && (
          <Input
            label={t('users.ID')}
            disabled
            value={PersonalCode}
            extraActions={['copy']}
          />
        )}

        {/* <Input disabled label={t('birthday')} value={birth} /> */}
      </form>

      <Button
        isLoading={isPending}
        disabled={!isActiveButton}
        label={t('save_changes')}
        variant="primary"
        onClick={saveChanges}
      />
      <h2 className={styles.title}>{t('change_password')}</h2>
      <Button label={t('update')} variant="primary" onClick={onUpdate} />
    </>
  );
}
