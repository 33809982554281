import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';

const baseURL = import.meta.env.VITE_OIDC_AUTHORITY;

interface Passwords {
  currentPassword: string;
  newPassword: string;
}

const updatePassword = async (data: Passwords, token: string) => {
  await axios.request({
    method: 'POST',
    baseURL,
    url: '/user/current/password',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      current_password: data.currentPassword,
      new_password: data.newPassword,
    },
  });
};

export function useUpdatePassword() {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useMutation({
    mutationFn: (passwords: Passwords) => updatePassword(passwords, token!),
  });
}
