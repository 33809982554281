import { useRef, useState } from 'react';
import { Button, Input, InputRef, useTranslations } from '@omnipkg/pa-ui-kit';

import stylesCommon from '../../ProfileMenu.module.scss';
import styles from './PasswordChange.module.scss';
import { useUpdatePassword } from './utils/updateUserPassword';

export default function PasswordChange() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const currentPasswordRef = useRef<InputRef>(null);
  const newPasswordRef = useRef<InputRef>(null);
  const confirmPasswordRef = useRef<InputRef>(null);

  const { t } = useTranslations();
  const { isPending, mutate } = useUpdatePassword();

  function processNewPassword() {
    const isPasswordValid = newPasswordRef.current?.isPasswordValid();

    if (!isPasswordValid) return;

    if (newPassword !== confirmPassword) {
      confirmPasswordRef.current?.setInputError(t('passwords_do_not_match'));
      return;
    }

    mutate(
      {
        currentPassword,
        newPassword,
      },
      {
        onError: (error) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const ids = (error as any)?.response?.data?.ids;
          if (typeof ids === 'string') {
            currentPasswordRef.current?.setInputError(t(ids));
          }
          console.error(error);
        },
        onSuccess: () => {
          setIsShowSuccess(true);
          setCurrentPassword('');
          setNewPassword('');
          setConfirmPassword('');
        },
      },
    );
  }

  return (
    <>
      <h2 className={stylesCommon.title}>{t('change_password')}</h2>
      <div>
        <p className={styles.passwordRequirements}>
          {t('password_requirements')}:
        </p>
        <div>
          <p>- {t('password_requirement_1')}</p>
          <p>- {t('password_requirement_2')}</p>
          <p>- {t('password_requirement_3')}</p>
          <p>- {t('password_requirement_4')}</p>
        </div>
      </div>
      <form className={stylesCommon.form}>
        <Input
          autoComplete="off"
          ref={currentPasswordRef}
          variant="password"
          label={t('current_password')}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <Input
          autoComplete="off"
          ref={newPasswordRef}
          variant="password"
          label={t('new_password')}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <Input
          autoComplete="off"
          ref={confirmPasswordRef}
          variant="password"
          label={t('confirm_password')}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </form>
      <Button
        isLoading={isPending}
        label={t('save_changes')}
        variant="primary"
        onClick={processNewPassword}
      />
      {isShowSuccess && (
        <h2 className={stylesCommon.title}>{t('change_password_success')}</h2>
      )}
    </>
  );
}
