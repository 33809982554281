import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { CommonResponse } from 'src/api/types';
import { Company } from 'src/api/useCompaniesData';
import { Profile } from 'src/api/useProfilesData';

export enum FilterIndex {
  COMPANY,
  PROFILE,
  STATUS,
}

export enum UserStatus {
  VERIFICATION_IS_NEEDED = 10,
  VERIFICATION_REQUEST_SENT = 20,
  ACTIVE = 30,
  DEACTIVATED = 40,
}

export interface Status {
  status: UserStatus;
}

interface Contract {
  number: string;
  uid: string;
}

interface Addresses {
  area?: string;
  city: string;
  house: string;
  office?: string;
  region?: string;
  street: string;
  type: number;
  zip_code: string;
}

interface CourierSettings {
  login: string;
  password: string;
  pin_codes: string[];
}

export interface User {
  courier_settings?: CourierSettings;
  addresses?: Addresses[];
  company: Company;
  contract: Contract;
  email: string;
  first_name: string;
  last_name: string;
  personal_code: string;
  phone: string;
  profile: Profile;
  rights: unknown;
  status?: UserStatus;
  uid: string;
}

export type RefetchUsers = (
  options?: RefetchOptions,
) => Promise<QueryObserverResult<CommonResponse<User[]>, Error>>;
