import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import type { APIResponse, UserV2 } from '@omnic/lk-core';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useCurrentUser } from 'src/hooks';

interface ContextProps {
  children: React.ReactNode;
}

interface UserContextValue {
  user?: UserV2;
  isUserLoaded: boolean;
  isUserLoading: boolean;
  isUserFetching: boolean;
  setUser: (user: UserV2) => void;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<APIResponse<UserV2, unknown>, Error>>;
}

const Context = createContext<UserContextValue | null>(null);

export const UserContextProvider = ({ children }: ContextProps) => {
  const [user, setUser] = useState<UserV2>();
  const {
    data,
    isFetched: isUserLoaded,
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    refetch,
  } = useCurrentUser();

  const value = useMemo(() => {
    return {
      user,
      isUserLoaded,
      isUserLoading,
      isUserFetching,
      setUser,
      refetch,
    };
  }, [user, isUserLoaded, isUserLoading, isUserFetching, refetch]);

  useEffect(() => {
    if (data?.data.data) {
      setUser(data.data.data);
    }
  }, [data]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useUserContext(): UserContextValue {
  const contextData = useContext(Context);
  if (!contextData)
    throw new Error(
      'Please use hook useUserContext inside UserContextProvider provider',
    );
  return contextData;
}
