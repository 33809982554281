import { useTranslations } from '@omnipkg/pa-ui-kit';
import { Cancel, Message, Success, Warning } from 'src/assets/icons';
import { UserStatus } from 'src/pages/Users/types';

import styles from './StatusContainer.module.scss';
import { Props } from './types';

export default function StatusContainer({ userStatus }: Props): JSX.Element {
  const { t } = useTranslations();

  const status = userStatus ? t(`users.status.${userStatus}`) : '-';

  function getIcon() {
    switch (userStatus) {
      case UserStatus.VERIFICATION_IS_NEEDED:
        return <Warning />;
      case UserStatus.VERIFICATION_REQUEST_SENT:
        return <Message />;
      case UserStatus.ACTIVE:
        return <Success />;
      case UserStatus.DEACTIVATED:
        return <Cancel />;
      default:
        return null;
    }
  }

  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{t('users.user_status')}</span>
      <div className={cn(styles.status, styles[`status${userStatus}`])}>
        <span>{status}</span>
        {getIcon()}
      </div>
    </div>
  );
}
