import { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import { RequiredAddress } from 'src/components/RequiredAddress';
import { SidebarProvider } from 'src/context';
import { UserContextProvider } from 'src/context/userContext';

import Footer from '../Footer';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';
import styles from './PageLayout.module.scss';
import type { PageLayoutProps } from './PageLayout.types';

export const PageLayoutContent: FC<PageLayoutProps> = ({ className }) => {
  return (
    <main className={cn(styles.root, className)}>
      <RequiredAddress />
      <Header className={styles.header} />
      <Sidebar className={styles.sidebar} />

      <div className={styles.container}>
        <section className={styles.content}>
          <Outlet />
        </section>
      </div>
      <Footer />
    </main>
  );
};

export const PageLayout: FC<PageLayoutProps> = (props) => {
  return (
    <UserContextProvider>
      <SidebarProvider>
        <PageLayoutContent {...props} />
      </SidebarProvider>
    </UserContextProvider>
  );
};
