import { UserV2 } from '@omnic/lk-core';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';

import { CommonResponse } from './types';

const baseURL = import.meta.env.VITE_OIDC_AUTHORITY;

interface Request {
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  addresses?: {
    type?: number;
    city?: string;
    street?: string;
    house?: string;
    zip_code?: string;
  }[];
}

const updateUser = async (data: Request, token: string) => {
  const res = await axios.request<CommonResponse<UserV2>>({
    method: 'PATCH',
    baseURL,
    url: '/user/current',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  });

  return res.data;
};

export function useUpdateUser() {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useMutation({
    mutationFn: (data: Request) => updateUser(data, token!),
  });
}
