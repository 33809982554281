import { UserV2 } from '@omnic/lk-core';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import { CommonResponse } from 'src/api/types';

const baseURL = import.meta.env.VITE_OIDC_AUTHORITY;

interface Request {
  email: string;
  prototype: number;
  contract_uid: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  redirect_url?: string;
}

const addUser = async (data: Request, token: string) => {
  await axios.request<CommonResponse<UserV2>>({
    method: 'POST',
    baseURL,
    url: '/invite/send',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export function useAddUser() {
  const { userData } = useAuth();
  const token = userData?.access_token;

  return useMutation({
    mutationFn: (data: Request) => addUser(data, token!),
  });
}
